import DashboardIcon from "@mui/icons-material/Dashboard";
import DeleteIcon from "@mui/icons-material/Delete";
import MessageIcon from "@mui/icons-material/Message";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SupervisedUserCircleRoundedIcon from "@mui/icons-material/SupervisedUserCircleRounded";
import { ReactComponent as AddEmpIcon } from "../assets/viewagent.svg";
import { ReactComponent as ViewAgent } from "../assets/viewEmp.svg";
import { ReactComponent as NotiIcons } from "../assets/notiIcons.svg";
import { ReactComponent as ViewApp } from "../assets/viewAppIcon.svg";
import { ReactComponent as AddApp } from "../assets/addAppIcon.svg";
import { ReactComponent as AppIcon } from "../assets/appicon.svg";
import { ReactComponent as CommissionIcons } from "../assets/comissionIcons.svg";
import { ReactComponent as CommiChildIcons } from "../assets/commChildicon.svg";
import { ReactComponent as TransactIcons } from "../assets/transactionIcons.svg";
import { ReactComponent as CommStructureIcons } from "../assets/commiStructureIcons.svg";
import { ReactComponent as ReportIcons } from "../assets/reportIcon.svg";
import { ReactComponent as ReportChildIcons } from "../assets/reportChildIcons.svg";
import { ReactComponent as NotiCreate } from "../assets/notiCreateIcons.svg";
import { ReactComponent as NotiList } from "../assets/notiListIcons.svg";
import { ReactComponent as NotiReceived } from "../assets/notiReceivedIcons.svg";
import { ReactComponent as bank } from "../assets/bank-building.svg";
import { ReactComponent as bankChildreen } from "../assets/bankchildren.svg";

import PreviewIcon from "@mui/icons-material/Preview";
import CardMembershipIcon from "@mui/icons-material/CardMembership";

export const SidebarUserItems = [
  {
    text: "User",
    icon: AccountBoxIcon,
    link: "user/",
    subItems: [
      { text: "View Employees", link: "/user/viewEmployee", icon: ViewAgent },
      { text: "View Agents", link: "/user/viewAgents", icon: ViewAgent },
      { text: "Add Employees", link: "/user/addEmployee", icon: AddEmpIcon },
      { text: "Add Agents", link: "/user/addAgent", icon: AddEmpIcon },
    ],
  },
];

export const ApplicationItems = [
  {
    text: "Applications",
    icon: AppIcon,
    link: "/applications",
    subItems: [
      {
        text: "Add Application",
        link: "/application/addApplication",
        icon: AddApp,
      },
      {
        text: "View Applications",
        link: "/application/viewApplications",
        icon: ViewApp,
      },
    ],
  },
];

export const Dashboard = [
  {
    text: "Dashboard",
    icon: DashboardIcon,
    link: "/dashboard",
  },
];

export const MerchantItem = [
  {
    text: "Merchants",
    icon: SupervisedUserCircleRoundedIcon,
    link: "/merchants",
  },
];

export const Commission = [
  {
    text: "Commission",
    icon: CommissionIcons,
    link: "/commission",
    subItems: [
      {
        text: "All Commission",
        icon: CommiChildIcons,
        link: "/commission/allCommission",
      },
      {
        text: "Commission Structure",
        icon: CommStructureIcons,
        link: "/commission/commissionStructure",
      },
      {
        text: "View Structure",
        icon: PreviewIcon,
        link: "/commission/viewStructure",
      },
      {
        text: "All Card rates",
        icon: CardMembershipIcon,
        link: "/commission/allCardRates",
      },
      // {
      //   text: "View Card Rates",
      //   icon: PreviewIcon,
      //   link: "/commission/viewCardRates",
      // },
    ],
  },
];

export const Bank = [
  {
    text: "Banks",
    icon: bank,
    link: "/bank",
    subItems: [
      {
        text: "Anna Bank",
        link: "/bank/annabank",
        icon: bankChildreen,
      },
      {
        text: "Zempler Bank",
        link: "/bank/zemplerbank",
        icon: bankChildreen,
      },
    ],
  },
];

export const TransactionReport = [
  {
    text: "Transaction Report",
    icon: TransactIcons,
    link: "/transactionReport",
  },
];

export const Reports = [
  {
    text: "Reports",
    icon: ReportIcons,
    link: "/reports",
    subItems: [
      {
        text: "Overview",
        link: "/report/overview",
        icon: ReportChildIcons,
      },
      {
        text: "Sales",
        icon: ReportChildIcons,
        link: "/report/overview/salesDetails",
      },
      // {
      //   text: "Revenue",
      //   icon: PersonIcon,
      //   link: "/report/overview/revenueDetails",
      // },
      {
        text: "Commission Recieved",
        icon: ReportChildIcons,
        link: "/report/overview/commissionReceivedDetails",
      },
      {
        text: "Commission Paid",
        icon: ReportChildIcons,
        link: "/report/overview/commissionPaidDetails",
      },
      {
        text: "Residual Received",
        icon: ReportChildIcons,
        link: "/report/overview/residualReceivedDetails",
      },
      {
        text: "Residual Paid",
        icon: ReportChildIcons,
        link: "/report/overview/residualPaidDetails",
      },
      {
        text: "Clawbacks",
        icon: ReportChildIcons,
        link: "/report/overview/clawbacks",
      },
      // {
      //   text: "Applications",
      //   icon: PersonIcon,
      //   link: "/application",
      // },
    ],
  },
];

export const Notification = [
  {
    text: "Notifications",
    icon: NotiIcons,
    link: "notifications/",
    subItems: [
      {
        text: "List",
        icon: NotiList,
        link: "/notifications/notificationList?screen=createdList",
      },
      {
        text: "Create",
        link: "/notifications/createNotification",
        icon: NotiCreate,
      },
      {
        text: "Received Notification",
        icon: NotiReceived,
        link: "/notifications/receivedNotification?screen=receivedList",
      },
    ],
  },
];

export const Messages = [
  {
    text: "Messages",
    icon: MessageIcon,
    link: "/messages",
  },
];

export const DeleteData = [
  {
    text: "Deleted Data",
    icon: DeleteIcon,
    link: "DeleteData/",
    subItems: [
      {
        text: "Employee",
        icon: DeleteIcon,
        link: "/user/viewEmployee?isDeleted=true",
      },
      {
        text: "Agent",
        icon: DeleteIcon,
        link: "/user/viewAgents?isDeleted=true",
      },
      {
        text: "Application",
        icon: DeleteIcon,
        link: "/application/viewApplications?isDeleted=true",
      },
      {
        text: "Merchant",
        icon: DeleteIcon,
        link: "/merchants?isDeleted=true",
      },

      {
        text: "Commission Structure",
        icon: DeleteIcon,
        link: "/commission/commissionStructure?isDeleted=true",
      },
      {
        text: "Transaction Report",
        icon: DeleteIcon,
        link: "/transactionReport?isDeleted=true",
      },
      {
        text: "List",
        icon: DeleteIcon,
        link: "/notifications/notificationList?screen=createdList&isDeleted=true",
      },
    ],
  },
];
