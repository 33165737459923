import React from "react";
import { Link } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Badge,
} from "@mui/material";
import { Colors } from "../../utils/Colors";

interface SidebarItem {
  text: string;
  link: string;
  icon: React.ElementType;
}

interface SidebarListItemProps {
  items: SidebarItem[];
  open?: boolean;
  location: { pathname: string };
  notificationShow?: boolean;
  sx?: React.CSSProperties;
}

const SideBarWithOutDropdown: React.FC<SidebarListItemProps> = ({
  location,
  items,
  open = false,
  notificationShow = false,
  sx,
}) => {
  return (
    <>
      {items.map((item) => {
        const isActive = location.pathname === item.link;

        return (
          <Link
            to={item.link}
            style={{
              textDecoration: "none",
              color: "inherit",
              padding: "0px 6px",
              marginTop: "0.2rem",
              ...sx,
            }}
            key={item.link}
          >
            <ListItem
              disablePadding
              sx={{
                display: "block",
                borderRadius: "10px",
                backgroundColor: isActive ? Colors.SideBarItembgcolor : "#fff",
                color: isActive ? Colors.background : "",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: "center",
                  borderRadius: "10px",
                  px: 2,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                  }}
                >
                  <item.icon
                    sx={{
                      color: location.pathname === item.link ? "#267DB1" : "",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{
                    color: "transparent",
                    background: isActive ? Colors.background : "#202020",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    opacity: open ? 1 : 0,
                    "& .MuiTypography-root": {
                      fontWeight: 700,
                      fontSize: "15px",
                      fontFamily: "Inter",
                    },
                  }}
                />
                {notificationShow && (
                  // <Box
                  //   sx={{
                  //     opacity: open ? 1 : 0,
                  //     backgroundColor: "#C10404",
                  //     p: "1px 0px 0px",
                  //     width: 15,
                  //     height: 15,
                  //     borderRadius: "50%",
                  //     textAlign: "center",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //   }}
                  // >
                  //   <Typography sx={{ fontSize: "10px", color: Colors.white, fontWeight: "700" }}>01</Typography>
                  // </Box>
                  <Badge color="error" badgeContent={1} showZero></Badge>
                )}
              </ListItemButton>
            </ListItem>
          </Link>
        );
      })}
    </>
  );
};

export default SideBarWithOutDropdown;
