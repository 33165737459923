export const Colors = {
  ButtonColor: "#589E58",
  SideBarItembgcolor: "#EEEBF4",
  SibarItemcolor: "#589E58",
  ExpandMoreColor: "#898989",
  LinkColor: "#267DB1",
  Textcolor: "#202020",
  InputBorderColor: "#DCDCDC",
  NotificationColor: "#F9837C",
  deletebtnColor: "#C10404",
  background:
    "linear-gradient(360deg, rgba(53, 214, 254, 1), rgba(35, 104, 159, 1), rgba(21, 16, 83, 1))",

  editColor: "#898989",
  successColor: "#1D427F",
  white: "#FFFFFF",
  blackColor: "#000000",
  moreColor: "rgba(137, 137, 137, 1)",
  graphBoxShadow:
    "2.468177556991577px 2.468177556991577px 2.468177556991577px 0px rgba(0, 0, 0, 0.1)",
  boxShadow1: "5.625px 7.5px 9.375px 0px #0000003D",
};
